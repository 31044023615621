import React, { FC, useEffect, useState, useContext } from "react";
import { IDelegationDashboardProps } from "../delegationDashboard.types";
import { messages } from "../delegationDashboard.messages";
import { injectIntl } from "react-intl";
import { Stack, Pivot, PivotItem,Icon } from "@fluentui/react";
import { ActiveDelegations } from "../Delegates/activeDelegations";
import { PeopleYouSupport } from "../Delegates/peopleYouSupport";
import { Reassignments } from "../Delegates/reassignment";
import { DelegationAPI } from "../../services/delegateapi";
import { setGlobalContext, isMockData } from "../../utils";
import TelemetryContext from "../../context/TelemetryContext";
import TelemetryService from "../../services/TelemetryService/TelemetryService";
import Enums from "../../services/TelemetryService/Enums";
import { PostDelegates } from "../Delegates/pastDelegation";
import { mergeStyles } from "office-ui-fabric-react";

document.body.style.overflowY='auto';
document.body.style.backgroundColor= '#FFFFFF'

const DelegationDashboardComponent: FC<IDelegationDashboardProps> = (props) => {
  const { parentContext, intl } = props;
  const [recordCount, setRecordCount] = useState(0); 
  const [reassignedRecordCount, setReassignedRecordCount] = useState(0);
  const [historyRecordCount, setHistoryRecordCount] = useState(0);
  const [peopleYouSupportrecordCount, setPeopleYouSupportRecordCount] =
    useState(0);
    const [yourDelegationHistory, setYourDelegationHistory] = useState(0);
const [onBehalfDelegationHistory, setOnBehalfDelegationHistory] = useState(0);
  const [selectedPivotKey, setSelectedPivotKey] =
    useState<string>("ActiveDelegations"); 
  const [appName, setAppName] = useState("");
  const { telemetryClient } = useContext(TelemetryContext);
  const updateRecordCount = (count: number) => {
    setRecordCount(count);
  };
  const updateHistoryRecordCount = () => {
    setHistoryRecordCount(prev => prev + 1);
  }
  const reassignedRecordCountUpdated = (count: number) => {
    setReassignedRecordCount(count);
  };
  const historyRecordCountUpdated = (count: number) => {
    setHistoryRecordCount(count);
  };
  // Function to announce the selection using ARIA live region
  const announceSelection = (message: string) => {
    const liveRegion = document.getElementById("selectionLiveRegion");
    if (liveRegion) {
      liveRegion.innerText = message;
    }
  };
  const containerStyle = {
    backgroundColor: '#dbeeff', // Matching background color from the image
    padding: '6px 12px', // Medium padding for balanced height
    border: '1px solid #c2d5f2',
    display: 'flex',
    verticalAlign: 'top',
    borderRadius: '3px', 
  };
  
  const infoIconStyle = mergeStyles({
    fontSize: '18px', 
    marginRight: '8px',
    color: '#000000',
    display: 'flex'
  });
  
  const headerStyle = {
    margingRight: '10px',
    fontSize: '14px', 
    fontWeight: 'normal',
    color: '#333',
    lineHeight: '1.2', 
  };

  useEffect(() => {
    setGlobalContext(parentContext, intl);
    const ismockdata = isMockData(parentContext);
    if (parentContext) {
      let api = new DelegationAPI();
      api
        .getDelegationInfo(parentContext, ismockdata)
        .then((re: any) => {
          if (re.data.appName && re.data.appName !== "") {
            setAppName(re.data.appName);
            let logData: any = {};
            TelemetryService.trackEvent(
              parentContext,
              telemetryClient,
              Enums.TrackingEvent
                .DelegationDashboardGetPeopleYouSupportProcessing,
              re.data.appName,
              logData
            );
          }
          api
            .getPeopleYouSupport(parentContext, ismockdata)
            .then((res: any) => {
              if (res.data && re.data !== "") {
                const count = res.data.length;
                setPeopleYouSupportRecordCount(count);
                for (let i = 0; i < res.data.length; i++) {
                  let logData: any = {};
                  logData[Enums.LogDataKey.PartnerName] =
                    res.data[i].partnerName;
                  logData[Enums.LogDataKey.ConsumerDocTypeId] =
                    res.data[i].consumerDocTypeId;
                  logData[Enums.LogDataKey.LoggedInUserEmail] =
                    res.data[i].loggedInUser.Email;
                  logData[Enums.LogDataKey.LoggedInUserObjectId] =
                    res.data[i].loggedInUser.Id;
                  logData[Enums.LogDataKey.LoggedInUserName] =
                    res.data[i].loggedInUser.Name;
                  logData[Enums.LogDataKey.DelegateEmail] =
                    res.data[i].delegate.Email;
                  logData[Enums.LogDataKey.DelegateObjectId] =
                    res.data[i].delegate.Id;
                  logData[Enums.LogDataKey.DelegateName] =
                    res.data[i].delegate.Name;
                  logData[Enums.LogDataKey.DelegatorEmail] =
                    res.data[i].delegator.Email;
                  logData[Enums.LogDataKey.DelegatorObjectId] =
                    res.data[i].delegator.Id;
                  logData[Enums.LogDataKey.DelegatorName] =
                    res.data[i].delegator.Name;
                  logData[Enums.LogDataKey.Permissions] =
                    res.data[i].permissions;
                  logData[Enums.LogDataKey.StartDate] = res.data[i].startDate;
                  logData[Enums.LogDataKey.EndDate] = res.data[i].endDate;
                  logData[Enums.LogDataKey.LoggedInUserNotes] =
                    res.data[i].loggedInUserNotes;
                  logData[Enums.LogDataKey.IsExpired] = res.data[i].isExpired;
                  logData[Enums.LogDataKey.IsLoggedInUserRemoved] =
                    res.data[i].isLoggedInUserRemoved;
                  logData[Enums.LogDataKey.DelegationStatus] =
                    res.data[i].delegationStatus;
                  TelemetryService.trackEvent(
                    parentContext,
                    telemetryClient,
                    Enums.TrackingEvent
                      .DelegationDashboardGetPeopleYouSupportSuccess,
                    re.data.appName,
                    logData
                  );
                }
              }
            })
            .catch((e: any) => {
              const stackTrace = e.stack || "";
              const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
              const failedMethod = failedMethodMatch
                ? failedMethodMatch[1]
                : null;
              // Extracting Exception type
              const exceptionType = e.message ? e.message.split(":")[0] : "";
              TelemetryService.trackException(
                parentContext,
                telemetryClient,
                Enums.TrackingEvent.DelegationDashboardGetPeopleYouSupportFail,
                e.response ? e.response.data : "",
                exceptionType,
                failedMethod,
                re.data.appName
              );
            });
        })
        .catch((e: any) => {});
      
        api
        .getressigned(parentContext, ismockdata)
        .then((res: any) => {
          if (res.data && res.data !== "") {
            const count = res.data.length;
            setReassignedRecordCount(count);
          }
        })
        .catch((e: any) => {
          const stackTrace = e.stack || "";
          const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
          const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

          // Extracting Exception type
          const exceptionType = e.message ? e.message.split(":")[0] : "";
          TelemetryService.trackException(
              parentContext,
              telemetryClient,
              Enums.TrackingEvent.GetreassignmentFail,
              e.response ? e.response.data : "",
              exceptionType,
              failedMethod,
              props.appName
          );
        })
        
        api.getdelegationhistory(parentContext)
  .then((res: any) => {
    if (res.data && res.data !== "") {
      const yourDelegationCount = res.data.yourDelegationHistory;
      setYourDelegationHistory(yourDelegationCount);

      const onBehalfDelegationCount = res.data.onBehalfDelegationHistory;
      setOnBehalfDelegationHistory(onBehalfDelegationCount);

      const count = yourDelegationCount.length + onBehalfDelegationCount.length;
      historyRecordCountUpdated(count);
    }
  })
  .catch((e: any) => {
    const stackTrace = e.stack || "";
    const failedMethodMatch = stackTrace.match(/at\s(\S+)\s\(/);
    const failedMethod = failedMethodMatch ? failedMethodMatch[1] : null;

    // Extracting Exception type
    const exceptionType = e.message ? e.message.split(":")[0] : "";
    TelemetryService.trackException(
        parentContext,
        telemetryClient,
        Enums.TrackingEvent.GetHistoryreassignmentFail,
        e.response ? e.response.data : "",
        exceptionType,
        failedMethod,
        props.appName
    );
  })
    }
    const announcement = `Selected tab: ${selectedPivotKey}`;
    announceSelection(announcement);
    // eslint-disable-next-line
  }, [parentContext, selectedPivotKey]);
  const handlePivotItemClicked = (item?: PivotItem) => {
    setSelectedPivotKey(item?.props.itemKey || ""); // Update the selected Pivot item key
  };

  const h1Style={
    marginTop:"20px",
    marginBottom:"20px",
    fontSize:"20px"
  }
  const renderContent = () => {
    return (
      <>
      <style>
        {
          `.page-root{
            width:99% !important
           }
          div.ms-TextField.is-required.is-disabled .ms-TextField-wrapper .ms-TextField-fieldGroup{
             border: none
           }
           div.ms-TextField.is-required .ms-TextField-wrapper .ms-TextField-fieldGroup{
             border: 1px solid rgb(96, 94, 92)
           }
           div.ms-TextField span{
             display: none;
           }`
        }
      </style>
      <Stack style={{overflowX:'hidden'
      // ,marginRight:'10px'
      }}>
        <div
            style={containerStyle}
            role="region" // Defines a region for the screen reader
            aria-labelledby="delegation-info-heading" // Associates with the heading
            >
              <div>
            <Icon
            iconName="Info"
            className={infoIconStyle}
            aria-hidden="true" // This is for decorative purposes, so hide it from screen readers
            />
            </div>
            <div id="delegation-info-heading" style={headerStyle}>
            Delegation is a new feature in MSApprovals and currently available for Compass requests. If you would like another application to onboard, please contact -<a href="mailto: delegationsup@microsoft.com"> Delegation Support</a>
            </div>
      </div>
        <h1 style={h1Style}>{props.intl.formatMessage(messages.pageTitle)}</h1>
        <Pivot
          selectedKey={selectedPivotKey}
          onLinkClick={handlePivotItemClicked}
          styles={{
            root: {
              overflowX: 'auto', // Enable horizontal scrolling
              whiteSpace: 'nowrap', // Prevent tab wrapping
            },
          }}
        >
          <PivotItem
            headerText={props.intl.formatMessage(messages.pivotItem1headerText)}
            itemCount={recordCount}
            itemKey="ActiveDelegations"
            headerButtonProps={{
              "data-order": 1,
              "data-title": "",
              "aria-label": "Active Delegations", 
              "aria-selected": selectedPivotKey === "ActiveDelegations", 
            }}
          >
            <ActiveDelegations
              parentContext={parentContext}
              updateRecordCount={updateRecordCount}
              updateHistoryRecordCount={updateHistoryRecordCount}
              appName={appName}
            />
          </PivotItem>
          <PivotItem
            headerText={props.intl.formatMessage(messages.pivotItem2headerText)}
            itemKey="PeopleYouSupport"
            itemCount={peopleYouSupportrecordCount}
            headerButtonProps={{
              "data-order": 2,
              "data-title": "",
              "aria-label": "People You Support", 
              "aria-selected": selectedPivotKey === "PeopleYouSupport", 
            }}
          >
            <PeopleYouSupport
              parentContext={parentContext}
              updateRecordCount={updateRecordCount}
              appName={appName}
            />
          </PivotItem>
          <PivotItem
            headerText={props.intl.formatMessage(messages.pastDelegationheaderText)}
            itemCount={historyRecordCount}
            itemKey="DelegationHistory"
            headerButtonProps={{
              "data-order": 1,
              "data-title": "",
              "aria-label": "DelegationHistory", 
              "aria-selected": selectedPivotKey === "DelegationHistory", 
            }}
          >
            <PostDelegates
              parentContext={parentContext}
              updateRecordCount={historyRecordCountUpdated}
              appName={appName}
            />
          </PivotItem>
          <PivotItem
            headerText={props.intl.formatMessage(messages.pivotItem3headerText)}
            itemKey="Reassignments"
            itemCount={reassignedRecordCount}
            headerButtonProps={{
              "data-order": 1,
              "data-title": "",
              "aria-label": "Re-Assignments", 
              "aria-selected": selectedPivotKey === "Reassignments",
            }}
          >
            <Reassignments
              parentContext={parentContext}
              updateRecordCount={reassignedRecordCountUpdated}
              appName={appName}
            />
          </PivotItem>
        </Pivot>
        <div
          id="selectionLiveRegion"
          aria-live="assertive"
          style={{
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: "1px",
            width: "1px",
            margin: "-1px",
            padding: "0",
            border: "0",
          }}
        />
      </Stack>
      </>
    );
  };

  const renderMain = (): JSX.Element => {
    return <Stack>{renderContent()}</Stack>;
  };
  return renderMain();
};

export const DelegationDashboard = injectIntl(DelegationDashboardComponent);
